@import "https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300";

html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	font-family: "Open Sans Condensed", sans-serif;
}

div[class*="box"] {
	height: 33.33%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.btn {
	line-height: 80px;
	height: 80px;
	text-align: center;
	width: 160px;
	cursor: pointer;
	padding-left: 5px;
	letter-spacing: 3px;
	border-radius: 100px;
}

.btn-three {
	color: #fff;
	transition: all 0.5s;
	position: relative;
	text-decoration: none;
	cursor: pointer;
	font-size: 30px;
	font-weight: 0;
}
.btn-three::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: rgba(255, 255, 255, 0.1);
	transition: all 0.3s;
	border-radius: 20px;
}
.btn-three:hover::before {
	opacity: 0;
	transform: scale(0.5, 0.5);
}
.btn-three::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border: 1px solid rgba(255, 255, 255, 0.5);
	transform: scale(1.2, 1.2);
}
.btn-three:hover::after {
	opacity: 1;
	transform: scale(1, 1);
}
